<template>
  <div>
    <v-sheet
      id="dropzone"
      ref="dzone"
      tabindex="0"
      width="100%"
      style="cursor:pointer;"
      height="200"
      class="pa-2 pt-4 dropzone"
    >
      <v-container>
        <v-row justify="center" class="mt-3">
          <v-icon color="primary" size="75">mdi-cloud-upload-outline</v-icon>
        </v-row>
        <v-row justify="center" class="text-center">
          <span class="text-h6 text-sm-small" v-if="!progress"
            >Arrastra y suelta o haz clic para
            {{ action ? action : "subir" }} el documento</span
          >
          <v-progress-linear
            v-if="progress"
            indeterminate
            class="mx-8"
          ></v-progress-linear>
        </v-row>
      </v-container>
    </v-sheet>
    <v-file-input
      ref="refFileInputImg"
      @change="onChangeImg"
      style="display:none"
      :value="fileModel"
      :accept="accept"
    ></v-file-input>
  </div>
</template>
<script>
export default {
  name: "FileDrop",

  data: () => ({
    formUpload: false,
    counterDragElements: 0,
    fileModel: null
  }),
  methods: {
    onChangeImg(file) {
      const firstFile = file && file.length ? file[0] : file;
      if (firstFile) this.$emit("files-selected", firstFile);
      this.emptyFile();
    },
    onClickDropZone(e) {
      const refImg = this.$refs["refFileInputImg"].$refs["input-slot"];
      if (refImg === e.target) return;
      e.preventDefault();
      refImg.firstElementChild.click();
      this.counterDragElements = 0;
    },
    emptyFile() {
      setTimeout(() => {
        const clearIcon = this.$el.getElementsByClassName(
          "v-input__icon v-input__icon--clear"
        )[0];
        if (clearIcon && clearIcon.children[0]) {
          clearIcon.children[0].click();
        }
      }, 0);
    }
  },
  props: {
    progress: null,
    action: null,
    accept: String()
  },
  mounted() {
    // to register listeners, we have to know the
    // html elements
    const dropzone = this.$el.firstElementChild;
    const fileupload = dropzone.firstElementChild;
    this.counterDragElements = 0;

    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", e => {
        e.preventDefault();
        this.counterDragElements++;
        dropzone.classList.add("dropzone-hover");
      });
      dropzone.addEventListener("dragleave", e => {
        e.preventDefault();
        this.counterDragElements--;
        if (this.counterDragElements === 0) {
          dropzone.classList.remove("dropzone-hover");
        }
      });
      dropzone.addEventListener("dragover", e => {
        e.preventDefault();
      });
      dropzone.addEventListener("drop", e => {
        e.preventDefault();
        dropzone.classList.remove("dropzone-hover");
        this.counterDragElements = 0;
        const dragevent = e;
        if (dragevent.dataTransfer) {
          this.$emit(
            "files-selected",
            dragevent.dataTransfer.files && dragevent.dataTransfer.files.length
              ? dragevent.dataTransfer.files[0]
              : dragevent.dataTransfer.files
          );
          this.emptyFile();
        }
      });

      // register event listener for keyboard usage
      dropzone.removeEventListener("click", this.onClickDropZone, false);
      dropzone.addEventListener("click", this.onClickDropZone, false);

      dropzone.addEventListener("keypress", e => {
        e.preventDefault();
        const keyEvent = e;
        if (keyEvent.key === "Enter") {
          if (fileupload) fileupload.click();
        }
      });
      // register listeners on the file input
      if (fileupload) {
        fileupload.addEventListener("change", e => {
          const target = e.target;
          if (target.files) {
            this.$emit(
              "files-selected",
              target.files && target.files.length
                ? target.files[0]
                : target.files
            );
            this.emptyFile();
          }
        });
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 500px) {
  .text-sm-small {
    font-size: 1rem !important;
  }
}
</style>
