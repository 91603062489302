<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiBookClockOutline"
    ></BaseBreadcrumb>

    <v-alert text outlined type="info" border="left">
      Sube un documento pdf para <b>añadirle</b> un sello de tiempo.
    </v-alert>

    <v-card>
      <file-drop
        v-on:files-selected="uploadFile"
        :progress="progress"
        :action="'sellar'"
        :accept="'application/pdf'"
      ></file-drop>
    </v-card>
    <v-container>
      <v-row class="mt-2" v-if="verifyPDF">
        <v-card @click="downloadFile" class="w-100 pa-2">
          <v-col cols="12" class="py-2">
            <a class="cursor-pointer cursor-hover-underline text-sm-xsmall">
              <v-icon color="green" class="">mdi-check-bold</v-icon>
              <v-icon color="primary" class="mr-3 ml-1">{{
                mdiFileDocumentOutline
              }}</v-icon
              >Descargar documento sellado</a
            >
          </v-col>
        </v-card>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import FileDrop from "@/components/commons/FileDrop.vue";
import { mdiFileDocumentOutline, mdiBookClockOutline } from "@mdi/js";
import axios from "axios";
import { DownloadFile } from "@/utils/utils";
import { FileToBinaryString } from "../../utils/utils";

export default {
  name: "TimestampSignature",

  components: {
    FileDrop
  },

  methods: {
    downloadFile() {
      DownloadFile(this.verifyPDF, this.nameFile);
    },
    uploadFile(file) {
      this.progress = true;
      this.verifyPDF = null;
      this.uploading = false;

      const widget = {
        type: 1,
        width: 0,
        height: 0,
        page: 1,
        x: 0,
        y: 0
      };

      const CancelToken = axios.CancelToken;
      let formData = new FormData();
      formData.append("pdfFile", file);
      for (var key in widget) {
        formData.append(`widget[${key}]`, widget[key]);
      }
      this.uploading = true;

      FileToBinaryString(file, 4).then(type => {
        if (type === "%PDF") {
          axios
            .post("eSign/timestamp", formData, {
              headers: { "Content-Type": "multipart/form-data" },
              responseType: "blob",
              cancelToken: new CancelToken(c => (this.cancel = c))
            })
            .then(response => {
              this.$toasted.global.toast_success({
                message: "El documento se ha sellado correctamente."
              });

              const file = new Blob([response.data], {
                type: "application/pdf"
              });
              const fileURL = URL.createObjectURL(file);
              this.verifyPDF = fileURL;
            })
            .catch(error => {
              this.$toasted.global.toast_error({
                message: !axios.isCancel(error)
                  ? "No se ha podido sellar el documento"
                  : "El servicio ha sido cancelado"
              });
            })
            .finally(() => {
              this.uploading = false;
              this.progress = false;
            });
        } else {
          this.$toasted.global.toast_error({
            message: "El tipo de archivo debe de ser pdf"
          });
          this.uploading = false;
          this.progress = false;
        }
      });

      return this;
    }
  },

  data: () => ({
    page: {
      title: "Añadir Sello De Tiempo"
    },
    verifyPDF: null,
    progress: false,
    uploading: false,
    mdiFileDocumentOutline: mdiFileDocumentOutline,
    mdiBookClockOutline: mdiBookClockOutline,
    iVerifyPDF: null,
    nameFile: "signed-document.pdf"
  })
};
</script>
<style scoped>
@media (max-width: 500px) {
  .text-sm-xsmall {
    font-size: 0.8rem !important;
  }
}
.cursor-hover-underline:hover {
  text-decoration: underline;
}
</style>
